import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import * as containerStyles from "../css/container.module.css"
import * as logoStyles from "../css/logo.module.css"

interface HeaderProps {
  siteTitle: string
}

const Header = ({ siteTitle }: HeaderProps) => (
  <header>
    <div className={containerStyles.container}>
      <Link to="/">
        <svg className={logoStyles.logo} viewBox="0 0 690 774" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M35.325 196.618L345 22.931l309.675 173.687A30 30 0 01670 222.784v328.432a29.998 29.998 0 01-15.325 26.165L345 751.069 35.325 577.381A29.998 29.998 0 0120 551.216V222.784a30.001 30.001 0 0115.325-26.166z" stroke="#1561BA" stroke-width="40"/><path d="M547 552l-116.142 65V438.001h-172.05V617L142 551.5V234l116.808-65v162.768h172.05V169L547 234v318z" fill="#FF344D"/></svg>
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
