/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "../css/layout.module.css"
import * as containerStyles from "../css/container.module.css"
import * as footerStyles from "../css/footer.module.css"

interface LayoutProps {
  children: ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const data: any = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  
  const siteTitle: string = data.site.siteMetadata?.title || `Content Management Consultant`

  return (
    <>
      <Header siteTitle={siteTitle} />
      <div className={containerStyles.container}>
        <main>{children}</main>
        <hr />
        <footer className={footerStyles.footer}>
          Powered by <a href="https://www.gatsbyjs.com">Gatsby</a>, built by <a href="https://hondo.xyz">Hondo</a> in <a href="https://www.georgetownma.gov">Georgetown</a>
        </footer>
      </div>
    </>
  )
}

export default Layout
